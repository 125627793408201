/**
 * 全局网络选择
 */
import React, { useMemo, useState, useCallback ,useEffect} from 'react';
import { Popover, Button, Menu, Input } from 'antd';
import { SettingOutlined  ,CheckOutlined , CloseCircleFilled } from '@ant-design/icons';
import { MenuProps } from 'antd/es/menu';
import { keys, get } from 'lodash';
import useGlobalState from '@/hooks/useGlobalState';
import IdNameComponent from './id-name';
import './style.less';
import { ReactComponent as SearchIcon } from '@/assets/img/searchIcon.svg';

const MenuItem = Menu.Item;
const HeadNetWork = () => {
  const [visible, setVisible] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [ filterUserList, setFilterUserList ] = useState<any>(null);
  const { currentUser, currentNetworkId, isLogin, setCurrentNetworkId, logout, networkUser } = useGlobalState();
  const getActiveRule = (pattern: string) => {
    return window.location.pathname===pattern;
  }
  //获取根节点
  const getRootDom = ()=>{
    return document.getElementById('master-root') || document.body
  }

  //控制弹出框显隐藏
  const handleVisibleChange = (v: boolean) => {
    setVisible(v)
  };
  const hide = () => {
    setVisible(false)
  };

  //跳转到设置页
  const handleNetworkConfigClick = () => {
    if (getActiveRule("/serving")){
      window.location.hash = `#!/network/detail`;
      // ....跳转过去的时候会把这个div 带过去
      const campaignTemplate  = document.getElementById('campaign-nav')
      campaignTemplate&&(campaignTemplate.style.display = 'none');
    }else{
      window.history.replaceState(null, '', '/serving#!/network/detail');
    }
  };
  const handleNetworkSearchClick = () => {
    setInputVisible(true)
  };
  const handleNetworkInputClick = (e:any) => {
    setInputVisible(false)
    setFilterUserList(null)
  }
  const handleNetworkInputChange = useCallback( (e:any) => {
      if(e.target.value.trim()){
       const networks = {...currentUser.networks,...currentUser.expiredNetworks}
       const filterUserList= keys(currentUser.networks).concat(keys(currentUser.expiredNetworks)).filter((item:string)=>{
          return (''+networks[item].networkId).indexOf(e.target.value.trim()) > -1 || getNetworkName(item).indexOf(e.target.value.trim()) > -1
          || getExpiredNetworksName(item).indexOf(e.target.value.trim()) > -1
        })
        setFilterUserList(filterUserList)
      }else{
        setFilterUserList(null)
      }
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser]);
  //切换当前网络
  const handleMenuItemClick: MenuProps['onClick'] = ({ key }) => {
    hide();
    setCurrentNetworkId(key);
    window.history.replaceState(null, '', `/serving2`);
    // 切换网络重新加载页面
    window.location.reload();
  };

  //从currentUser中获取网络Id对应的网络名称
  const getNetworkName = useCallback(
    (networkId?: number | string) => {
      if (!isLogin) return '加载中...';
      return get(currentUser, `networks[${networkId}].network.name`, '');
    },
    [currentUser, isLogin],
  );
 //从expiredNetworks中获取网络Id对应的网络名称
 const getExpiredNetworksName = useCallback(
  (networkId?: number | string) => {
    if (!isLogin) return '加载中...';
    return get(currentUser, `expiredNetworks[${networkId}].network.name`, '');
  },
  [currentUser, isLogin],
);
  useEffect(()=>{
    setInputVisible(false);
  },[currentNetworkId])
  const Title = useMemo(
    () => (
      networkUser && currentUser && <div className='flex-between-middle'>
        {/* <Button danger onClick={logout} size='small' type='primary'>
          退出
        </Button> */}
         {inputVisible?
         <div className='network-left'>
            <Input style={{width:'221px'}} onChange={handleNetworkInputChange} placeholder='请输入' suffix={
              <CloseCircleFilled style={{color:'#C0C2C9'}} onClick={handleNetworkInputClick} />
            }/>
         </div>
         :<div className='network-left'>
           <div className='network-left-top'>
            <h4>
             {currentUser.name}
            </h4>
            <span>{(networkUser.networkRole && networkUser.networkRole.name) ||'创建人'}</span>
          </div>
          <span className='network-left-email'>{currentUser.email}</span>
        </div>}
        <div className='network-right'>
          {!inputVisible &&  <SearchIcon style={{marginRight:'8px',cursor:'pointer'}} onClick={handleNetworkSearchClick} className='anticon anticon-setting' />}
          <SettingOutlined onClick={handleNetworkConfigClick} />
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [networkUser, currentUser, inputVisible, handleNetworkInputChange]
  );
  const content = (
    <div className ='network-popover-content'>
      <div className='network-list-ul'>
        <Menu selectedKeys={[`${currentNetworkId}`]} onClick={handleMenuItemClick}>
        {(filterUserList || keys(currentUser.networks)).map((networkId: string ) => (
            <MenuItem key={networkId}>
              <IdNameComponent {...{ id: networkId, name: getNetworkName(networkId)|| getExpiredNetworksName(networkId)}} />
              {currentNetworkId===Number(networkId) && <CheckOutlined className='checkOutlined' />}
            </MenuItem>
          ))}
          {!filterUserList && keys(currentUser.expiredNetworks).map((networkId: string ) => (
            <MenuItem key={networkId} disabled>
              <IdNameComponent {...{ id: networkId, name: getExpiredNetworksName(networkId)+'(已过期)' }} />
              {currentNetworkId===Number(networkId) && <CheckOutlined className='checkOutlined' />}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className='network-footer'>
          <Button danger onClick={logout} size='small' type='link'>
          退出登录
        </Button>
      </div>
    </div>
  );
  return (
    <Popover visible={visible}
      onVisibleChange={handleVisibleChange}
      content={content} 
      title={Title} 
      trigger="click"
      placement="bottom"
      id="main-popover"
      getPopupContainer={()=> getRootDom()}
      overlayClassName='main-network-content'
      >
      <div className='main-network'>
        <div className="infoWrap">
          <span className="infoAd">
            <IdNameComponent
              {...{
                id: currentNetworkId,
                name: getNetworkName(currentNetworkId),
                icon:true
              }}
            />
          </span>
          {/* <span className="infoRole">{currentUser.name}({(isLogin && networkUser?.networkRole?.name) || '创建者'})</span> */}
        </div>
      </div>
    </Popover>
  )
};

export default HeadNetWork;
