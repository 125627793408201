/*
 * @Description: 
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2022-03-15 17:14:39
 */
/**
 * 头部
 */
import React, {useMemo, useState,useEffect } from 'react';
import { Link,useLocation } from 'react-router-dom';
import { Layout, Tooltip, Menu } from 'antd';
import logo from '@/assets/img/logo.png';
import juyoulogo from '@/assets/img/juyou_white.png';
import erachIcon from '@/assets/img/erach@2x.png';
import useGlobalState from '@/hooks/useGlobalState';
import HeadMenu from '../HeadMenu';
import HeadSearch from '../HeadSearch';
import HeadNetwork from '../HeadNetwork';

import './style.less';
const { Header } = Layout;
const goHome = (e: any) => {
  window.history.replaceState(null, '', '/serving2');
};
const MainHeader = (props: any) => {
  const { pathname } = useLocation();
  const { currentUser } = useGlobalState();
  const [current, setCurrent] = useState('/serving2');
  const [isJuyou,setIsJuyou] = useState(false)
  //设置选中项目
  const getSelectedKeys = useMemo(() => {
    const list = pathname.split('/').splice(1);
    const returnList = list.map((item, index) => `/${list.slice(0, index + 1).join('/')}`);
    let curPath = ''
    if (returnList[0] === '/' || returnList[0] === '/serving' || returnList[0] === '/serving2') {
      curPath = '/serving2';
    }
    if (returnList[0] === '/rta') {
      curPath = '/rta';
    }
    setCurrent(curPath)
    return curPath
  }, [pathname]);

  // 顶部tab
  const items = [
    {
      title: '品牌程序化',
      key: '/serving2',
      newTab: false
    },
    {
      title: 'RTA',
      key: '/rta',
      newTab: true,
    }
  ];
  //点击切换tab
  const handleClick = (e: any) => {
    e.domEvent.stopPropagation()
    setCurrent(e.key)
    const targetMenu = items.find((menu) => e.key === menu.key);
    if (targetMenu?.newTab) {
      // window.open(targetMenu.key, '_blank');
      window.location.replace(targetMenu.key);
    } else {
      window.history.replaceState(null, '', targetMenu?.key);
    }
  };
  useEffect(()=>{
    if(document.domain === 'dsp.tangelo.com.cn'){
      setIsJuyou(true)
    }
  },[])
  return (
    <div className="main-header-con" style={{ position: 'fixed', top: 0, zIndex: 999, width: '100%' }}>
      {/* 头部tab */}
      <div className="main-tab">
        <div className="logoWrap">
          {isJuyou?<img onClick={goHome} className='logoImg' src={juyoulogo} alt='smart-serving' />:<img onClick={goHome} className='logoImg' src={logo} alt='smart-serving' />}
          
        </div>
        <div className="tabWrap">
          <Menu theme="dark" onClick={handleClick} selectedKeys={[getSelectedKeys]} mode="horizontal">
            {items.map((item) => (
              <Menu.Item key={item.key} data-new-tab={item.newTab}>
                {item.title}
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className='networkWrap'>
          <HeadNetwork />
        </div>
      </div>
      {/* serving系统 */}
      {
        current === '/serving2' && <Header className="main-header">
        <div className="menuWrap">
          <HeadMenu routes={props.routes} />
          {currentUser.role === 'admin' && <div className='iconWrap'>
            <Link to="/admin-v2/" target="_blank">
              <Tooltip placement="bottom" title={'运营平台'} mouseEnterDelay={1}>
                <img className='erachIcon' src={erachIcon} alt='管理后台' />
              </Tooltip>
            </Link>
          </div>}
          <div className="searchWrap">
            <HeadSearch />
          </div>
        </div>
      </Header>
      }
    </div>

  );
};

export default MainHeader;
